function toggleField($field, enable, isOptional = false) {
  const $input = $field.find(":input");
  const $label = $field.find("label");

  // Enable or disable the input
  $input.attr("disabled", !enable);

  // Add or remove the required attribute on the input
  $input.attr("required", enable && !isOptional);

  // Update field visibility
  $field.toggleClass("d-none", !enable).toggleClass("d-block", enable);

  // Update the label dynamically
  $label.find('sup.text-danger').remove();

  const labelText = $label.text();

  $label.html(enable && !isOptional ? `${labelText} <sup class="text-danger">*</sup>` : labelText);
}

function updateOccupationFields() {
  const $option = $("#occupation_id option:selected");
  const $needRegistration = $(".need-registration");
  const $suggestedOccupation = $(".suggested-occupation");
  const isOtherOption = $option.text() === "Outros";

  // Update the need-registration field based on the selected option
  toggleField($needRegistration, !!$option.data("need-registration"), isOtherOption);

  // Show or hide the suggested-occupation field if "Outros" is selected
  toggleField($suggestedOccupation, isOtherOption);
}

function moveOptionToEnd(select, text) {
  const optionToMove = select.find("option").filter(function () {
    return $(this).text().trim() === text;
  });

  // Move the specified option to the end of the select
  if (optionToMove.length) {
    optionToMove.detach().appendTo(select);
  }
}

$(document).ready(function () {
  const $select = $("#occupation_id");

  // Move "Outros" to the end of the select on page load
  moveOptionToEnd($select, "Outros");

  // Update fields on page load based on the selected option
  updateOccupationFields();

  // Clear fields and update visibility when the selection changes
  $select.change(function () {
    $(".need-registration :input, .suggested-occupation :input").val(""); // Clear all input fields
    updateOccupationFields(); // Update fields based on the new selection
  });
});
