import consumer from './consumer';
import ConferenceModule from '../packs/conference_module.js';

(function() {
  // jQuery selectors
  const $conferencePage = $("#conference-page");
  const isConferencePage = $conferencePage.length > 0;

  // Early exit if it is not a conference page
  if (!isConferencePage) return;

  let countdown;
  let conferenceChannel;
  let isCountdownRunning = false;

  // DOM elements
  const $countdownTimer = $('.countdown-control .countdown-timer');

  const $btnStartConference = $(".btn-start-conference");
  const $btnVideo = $(".btn-video");
  const $btnMicrophone = $(".btn-microphone");
  const $btnShare = $(".btn-share");
  const $btnFullscreen = $(".btn-fullscreen");
  const $btnFinish = $(".btn-finish");

  const $previewStream = $(".previewStream");
  const $previewStreamVideo = $("[data-preview-stream]");

  const $localStream = $(".localStream");
  const $localStreamVideo = $("[data-local-stream]");

  const $remoteStream = $(".remoteStream");
  const $remoteStreamVideo = $("[data-remote-stream]");
  const $shareStreamVideo = $("[data-share-stream]");

  const $userId = $("[data-user-id]");
  const $professionalId = $("[data-professional-user-id]");
  const $conference = $("#conference");
  const $previewConference = $("#preview-conference");

  const $timer = $('#timer');
  const $connectionControl = $('.connection-control');
  const $countdownControl = $('.countdown-control');

  // User identification
  const isUser = $userId.length > 0;
  const isProfessional = $professionalId.length > 0;

  // Conference data
  const roomID = $conferencePage.data("conference-room-id");
  const partnerName = $conferencePage.data("partner-name");
  const conferenceStatus = $conferencePage.data("conference-status");
  const conferenceStarted = conferenceStatus === "in_progress";
  const authenticityToken = $("meta[name='csrf-token']").attr("content");

  function secondsTimeSpanToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  function showAlert(message) {
    const $alert = $(`
      <div class="alert alert-timer alert-light shadow-sm alert-dismissible text-center show fade" role="alert">
        ${message}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `);

    $conferencePage.append($alert.slideDown().delay(10000).slideUp());
  }

  function updateCountdownTimer(totalTime) {
    if (isCountdownRunning) return;

    isCountdownRunning = true;

    $countdownTimer.html(totalTime);

    countdown = setInterval(() => {
      $countdownTimer.html(totalTime--);

      if (totalTime < 0) {
        clearInterval(countdown);

        isCountdownRunning = false;

        setTimeout(() => {
          $countdownTimer.html('Ligação finalizada!');
          $.post(`/conferences/finish/${roomID}`, {
            authenticity_token: authenticityToken
          });
        }, 1000);
      }
    }, 1000);
  }

  function setVisibility($element, isVisible) {
    isVisible ? $element.show() : $element.hide();
  }

  // Creating a new instance of ConferenceModule to manage the conference
  const conference = new ConferenceModule({
    roomID: roomID,
    iceServers: window.iceServers || [{
      urls: [
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302"
      ]
    }],
    localStreamElement: $localStreamVideo[0],
    remoteStreamElement: $remoteStreamVideo[0],
    shareStreamElement: $shareStreamVideo[0]
  });

  // Utility functions for UI and stream control
  function showConference() {
    $previewConference.remove();
    $conference.removeClass("d-none");
  }

  function turnOffVideo() {
    $remoteStream.addClass("video-off");
    window.addToast(`${partnerName} está com a câmera desligada`, "fa-video-slash");
  }

  function turnOnVideo() {
    $remoteStream.removeClass("video-off");
    window.addToast(`${partnerName} está com a câmera ligada`, "fa-video");
  }

  function turnOffAudio() {
    $remoteStream.addClass("microphone-off");
    window.addToast(`${partnerName} está com o microfone desligado`, "fa-microphone-slash");
  }

  function turnOnAudio() {
    $remoteStream.removeClass("microphone-off");
    window.addToast(`${partnerName} está com o microfone ligado`, "fa-microphone");
  }

  // Video and microphone button control
  function btnVideoOn() {
    $previewStream.removeClass("video-off");
    $localStream.removeClass("video-off");

    $btnVideo.removeClass("btn-danger text-white").addClass("btn-light");
    $btnVideo.find(".fas").removeClass("fa-video-slash text-white").addClass("fa-video");

    conference.setLocalStreamStatus({ video: true });

    window.addToast("Câmera ligada", "fa-video");
  }

  function btnVideoOff(options = {}) {
    const { disabled = false } = options;

    if (disabled) {
      $btnVideo.attr("disabled", true);
    }

    $previewStream.addClass("video-off");
    $localStream.addClass("video-off");

    $btnVideo.removeClass("btn-light").addClass("btn-danger text-white");
    $btnVideo.find(".fas").removeClass("fa-video text-white").addClass("fa-video-slash text-white");

    conference.setLocalStreamStatus({ video: false });

    window.addToast("Câmera desligada", "fa-video-slash");
  }

  function btnAudioOn() {
    $previewStream.removeClass("microphone-off");
    $localStream.removeClass("microphone-off");

    $btnMicrophone.removeClass("btn-danger text-white").addClass("btn-light");
    $btnMicrophone.find(".fas").removeClass("fa-microphone-slash text-white").addClass("fa-microphone");

    conference.setLocalStreamStatus({ audio: true });

    window.addToast("Microfone ligado", "fa-microphone");
  }

  function btnAudioOff(options = {}) {
    const { disabled = false } = options;

    if (disabled) {
      $btnMicrophone.attr("disabled", true);
    }

    $previewStream.addClass("microphone-off");
    $localStream.addClass("microphone-off");

    $btnMicrophone.removeClass("btn-light").addClass("btn-danger text-white");
    $btnMicrophone.find(".fas").removeClass("fa-microphone text-white").addClass("fa-microphone-slash text-white");

    conference.setLocalStreamStatus({ audio: false });

    window.addToast("Microfone desligado", "fa-microphone-slash");
  }

  function toggleVideo() {
    if ($btnVideo.find(".fas.fa-video").length > 0) {
      btnVideoOff();
    } else {
      btnVideoOn();
    }
  }

  function toggleAudio() {
    if ($btnMicrophone.find(".fas.fa-microphone").length > 0) {
      btnAudioOff();
    } else {
      btnAudioOn();
    }
  }

  function startConferenceError(text) {
    $btnStartConference.before(`<p class='text-danger'>${text}</p>`);
    $btnStartConference.attr("disabled", true);
  }

  // Function to start the conference
  function actionStartConference() {
    conferenceChannel = consumer.subscriptions.create({ channel: 'ConferenceChannel', room_id: roomID }, {
      connected() {
        clearInterval(countdown);

        isCountdownRunning = false;

        console.log('Conference channel connected');
      },

      disconnected() {
        console.log('Conference channel disconnected');
      },

      received(data) {
        if (data?.action !== "timer") {
          console.log('Conference channel received', data);
        }

        if (data.sender) {
          if ((data.sender === 'user' && isProfessional) || (data.sender === 'professional' && isUser)) {
            if (data?.message?.type === 'screen-sharing-started') {
              window.addToast(`${partnerName} iniciou o compartilhamento da tela`, "fa-desktop");
            }

            if (data?.message?.type === 'screen-sharing-stopped') {
              window.addToast(`${partnerName} encerrou o compartilhamento da tela`, "fa-desktop");
            }

            if (data?.message?.video !== undefined) {
              if (data.message.video) {
                turnOnVideo();
              } else {
                turnOffVideo();
              }

              return;
            }

            if (data?.message?.audio !== undefined) {
              if (data.message.audio) {
                turnOnAudio();
              } else {
                turnOffAudio();
              }

              return;
            }

            if (data.message?.type && data.message.type === "offer") {
              conference.handleVideoOfferMsg(data.message);
              return;
            }

            if (data.message?.type && data.message.type === "answer") {
              conference.handleVideoAnswerMsg(data.message);
              return;
            }

            if (data.message?.candidate) {
              conference.handleNewICECandidateMsg(data.message);
              return;
            }
          }
        }

        if (data.action) {
          switch (data.action) {
            case 'timer':
              $timer.html(secondsTimeSpanToHMS(data.currentTime));
              setVisibility($connectionControl, false);

              if (data.alert) {
                showAlert(data.alert);
              }

              break;
            case 'stop_connection_failure_countdown':
              setVisibility($countdownControl, false);
              $countdownTimer.html('60');
              clearInterval(countdown);

              isCountdownRunning = false;

              break;
            case 'start_connection_failure_countdown':
              setVisibility($connectionControl, false);
              setVisibility($countdownControl, true);
              $countdownTimer.html('60');
              updateCountdownTimer(59);

              break;
            case 'start_conference':
              if ((data.lastUserConnected === 'user' && isUser) || (data.lastUserConnected === 'professional' && isProfessional)) {
                console.log('start_conference');
                conference.addTracks();
              }

              break;
            default:
              console.warn('Unknown action received:', data.action);
          }
        }
      }}
    );

    // Display the conference in the UI
    showConference();
  }

  // Function to activate fullscreen mode
  function actionFullscreen() {
    const $fullscreen = $remoteStreamVideo;
    const requestFullscreen = $fullscreen[0].requestFullscreen || $fullscreen[0].webkitRequestFullscreen || $fullscreen[0].msRequestFullscreen;

    if (requestFullscreen) {
      requestFullscreen.call($fullscreen[0]);
    } else {
      $remoteStreamVideo.hide();
    }
  }

  // Make the localStream element draggable
  window.draggable({
    drag: $localStream,
    area: $conferencePage
  });

  // Set up button events
  $btnStartConference.on("click", function() {
    actionStartConference();
  });

  $btnFullscreen.on("click", function() {
    actionFullscreen();
  });

  $btnVideo.on("click", function() {
    toggleVideo();
  });

  $btnMicrophone.on("click", function() {
    toggleAudio();
  });

  $btnShare.on("click", function() {
    conference.startShareScreen();
  });

  $btnFinish.on("click", function(event) {
    $.post(`/conferences/finish/${roomID}`, {
      authenticity_token: authenticityToken
    });

    event.preventDefault();
    event.stopPropagation();
  });

  // Listening to conference events
  conference.on("send", function(message) {
    if (message?.candidate) {
      console.log(message);
    }

    conferenceChannel.send({
      sender: isProfessional ? 'professional' : 'user',
      message: message
    });
  });

  conference.on("mediaStream", function(localStream) {
    $previewStreamVideo[0].srcObject = localStream;
    $localStreamVideo[0].srcObject = localStream;
  });

  conference.on("disabledBtnVideo", function() {
    btnVideoOff({ disabled: true });
  });

  conference.on("disabledBtnAudio", function() {
    btnAudioOff({ disabled: true });
  });

  conference.on("mediaStreamError", function() {
    startConferenceError('Erro ao obter o stream de mídia. Verifique as permissões e dispositivos de entrada.');
  });

  conference.on("audioDeviceError", function() {
    startConferenceError('Não encontramos um dispositivo de entrada de áudio.<br />Por favor, verifique se as permissões de acesso ao microfone estão habilitadas.');
  });

  conference.on("connected", function() {
    if (isProfessional && !conferenceStarted) {
      $.post(`/conferences/start/${roomID}`, {
        authenticity_token: authenticityToken
      });
    }
  });

  conference.on("disconnected", function() {
    $remoteStream.addClass("video-off microphone-off");
  });

  conference.on("failed", function() {
    $remoteStream.addClass("video-off microphone-off");
  });

  conference.on("closed", function() {
    $remoteStream.addClass("video-off microphone-off");
  });
}());
