var $profile = $(".profiles #tabs");

if ($profile.length) {
  var url = location.href.replace(/\/$/, "");

  $profile
    .find('a[data-toggle="collapse"], a[data-toggle="tab"]')
    .on("click", function (event) {
      // URL
      var hash = $(this).attr("href");
      var newUrl = url.split("#")[0] + hash;

      history.replaceState(null, null, newUrl);

      // COLLAPSE
      var $collapse = $profile.find("#collapse-" + hash.split("#")[1]);

      $collapse.collapse("show");
      $collapse.find("form").trigger("reset");

      // TAB
      $profile.find('.nav-tabs a[href="#' + hash.split("#")[1] + '"]').tab("show");
      $profile.find(hash).find("form").trigger("reset");

      $("html, body").scrollTop(0);

      event.preventDefault();
      event.stopPropagation();
    }
  );

  if (location.hash) {
    var hash = url.split("#");

    // COLLAPSE
    $profile.find("#collapse-" + hash[1]).collapse("show");

    // TAB
    $profile.find('.nav-tabs a[href="#' + hash[1] + '"]').tab("show");

    setTimeout(function () {
      $("html, body").scrollTop(0);
    }, 400);
  }
}

$('[data-tab="external"]').on("click", function (event) {
  var targetId = $(this).attr("data-target");
  var $modal = $(this).closest('.modal');

  if ($modal.length) {
    $modal.modal('hide');
  }

  $profile.find('a[href="' + targetId + '"]').click();
});

$profile.on('click', '[data-toggle="collapse"]', function (event) {
  var $target = $($(this).data('target'));

  if ($target.hasClass('show')) {
    return false;
  }

  event.preventDefault();
  event.stopPropagation();
});

$('[data-focus]').on('click', function() {
  var target = $($(this).data('focus'));

  if (target.length) {
    setTimeout(function() {
      $('html, body').animate({ scrollTop: target.offset().top - ($(window).height() / 2) + target.outerHeight() / 2 }, 300);
      target.focus();
    }, 300);
  }
});
